import { useEffect, useState, useRef } from 'react';
import { useOnOutsideClick } from 'app/hooks/use-on-outside-click.hook';
import { FindLoadsResultsState } from 'shared/find-loads/redux/find-loads.reducers';
import { Banner } from '@chr/eds-react';
import { useTranslation } from 'react-i18next';

interface Props {
  results: FindLoadsResultsState;
  changeSearchSelection: () => any;
}

export const LoadNotFoundBanner = ({ results, changeSearchSelection }: Props) => { 
  const { t } = useTranslation();

  const notBookedLoads = !results?.carrierBooks ? results?.loads : results?.loads?.filter(c => !results?.carrierBooks?.some(item => item.loadNumber === c.number));
  const hasSuggestedLoads = results?.performedSuggestedLoadSearch && notBookedLoads?.length > 0;
  const emptyResults = results?.performedSuggestedLoadSearch && notBookedLoads;
  const title = hasSuggestedLoads ? t('SORRY_LOAD_NO_LONGER_AVAILABLE_WITH_SUGGESTED_LOADS') : t('SORRY_LOAD_NO_LONGER_AVAILABLE');

  const [wasDismissed, setWasDismissed] = useState<boolean>(false);
  useEffect(() => setWasDismissed(false), [results]);

  const bannerRef = useRef<HTMLDivElement>();
  useOnOutsideClick(bannerRef, () => setWasDismissed(true));

  const changeSearchSelectionHandler = () => {
    setWasDismissed(true);
    changeSearchSelection();
  };

  if (emptyResults && !wasDismissed) {
    return (
      <div id="load-not-found-banner" aria-live="assertive" style={{ paddingBottom: "10px" }} ref={bannerRef} data-testid='load-not-found-banner'>
        <Banner
          title={title}
          severity="error"
          actionOne={{
            buttonText: t('SEARCH_BY_ORIGIN_OR_DESTINATION'),
            onClick: changeSearchSelectionHandler,
          }}
          actionTwo={{
            buttonText: t('DISMISS'),
            onClick: () => setWasDismissed(true),
          }}
        />
      </div>
    );
  }

  return <></>;
};
