import { useEffect, useState, useRef } from 'react';
import { useOnOutsideClick } from 'app/hooks/use-on-outside-click.hook';
import { FindLoadsResultsState } from 'shared/find-loads/redux/find-loads.reducers';
import { Banner } from '@chr/eds-react';
import { useTranslation } from 'react-i18next';

interface Props {
  results: FindLoadsResultsState;
  reload?: number;
}

export const ReloadNotFoundBanner = ({results, reload}: Props) => {
  const { t } = useTranslation();

  const notBookedLoads = !results?.carrierBooks ? results?.loads : results?.loads?.filter(c => !results?.carrierBooks?.some(item => item.loadNumber === c.number));
  const reloadExist = !reload || notBookedLoads?.some(c => c.number === reload);

  const [wasDismissed, setWasDismissed] = useState<boolean>(false);
  useEffect(() => setWasDismissed(false), [results]);
  
  const bannerRef = useRef<HTMLDivElement>();
  useOnOutsideClick(bannerRef, () => setWasDismissed(true));

  if (!reloadExist && !wasDismissed) {
    return (
      <div id="reload-not-found-banner" aria-live="assertive" style={{ paddingBottom: "5px" }} ref={bannerRef} data-testid='reload-not-found-banner'>
        <Banner
          title={t('RELOAD_IS_UNAVAILABLE')}
          severity="error"
          actionOne={{
            buttonText: t('DISMISS'),
            onClick: () => setWasDismissed(true),
          }}
        />
      </div>
    );
  }

  return <></>;
};

export default ReloadNotFoundBanner;
