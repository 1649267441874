import { Component } from 'react';
import { ChangeEvent } from 'react';
import { Container } from 'typedi';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { cancelRequest } from 'app/util/util';
import { GeographyRepository } from 'app/repositories/geography.repository';
import { RadiusSelection } from 'shared/find-loads/components/radius-selection.component';
import { LocalAutocomplete } from 'shared/components/location-select/location-autocomplete/local-autocomplete.component';
import { LocationSelectContext, LocationSelectContextType } from 'shared/components/location-select/location-select-context.component';
import { RetrieveLocations } from 'shared/components/location-select/location-functions';

const baseLocation: LocationSelectGrouping = {
  country: null,
  stateProv: null,
  city: null,
  place: null,
  postalCode: null,
  radius: null,
};

interface Props {
  id?: string;
  label?: string;
  required?: boolean;
  cityRequired?: boolean;
  onLocationChange: (LocationSelectGrouping) => any;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => any;
  value?: LocationSelectGrouping;
  showRadius: boolean;
  isMetric: boolean;
  useV2Autocomplete?: boolean;
}

export class LocationRadius extends Component<Props> {
  private repository = Container.get(GeographyRepository);
  private pendingPlaces: Subscription;

  componentWillUnmount() {
    cancelRequest(this.pendingPlaces);
  }

  fetchLocations = (query: string) => {
    return RetrieveLocations(query, this.pendingPlaces, this.repository, this.state);
  };

  onPlaceChange = (place: any) => {
    const location = this.props.value;
    this.props.onLocationChange({ ...baseLocation, ...location, place });
  };

  onRadiusChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    const radius = value ? Number(value) : null;
    const location = this.props.value;

    this.props.onLocationChange({ ...baseLocation, ...location, radius });
  };

  render() {
    const { label, required, value: location, id, cityRequired, onBlur, useV2Autocomplete } = this.props;
    const { onPlaceChange, fetchLocations, onRadiusChange } = this;
    const context: LocationSelectContextType = {
      location,
      label, id,
      onPlaceChange,
      fetchLocations,
      onRadiusChange,
      onBlur,
    };

    return (
      <LocationSelectContext.Provider value={context}>
        {
          <div className="location-select-component" id={`${id || label}-location-select`}>
            <div className="location-radius">
              <div className="location-selector js-location-select-place" id="Place">
                <LocalAutocomplete required={(cityRequired ?? required)} useV2={useV2Autocomplete} />
              </div>
              {this.props.showRadius &&
                <div className="selection-header-radius">
                  <RadiusSelection ariaControls="radius-selection-collapsible" isMetric={this.props.isMetric} />
                </div>
              }
            </div>
          </div>
        }
      </LocationSelectContext.Provider>
    );
  }
}