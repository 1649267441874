import { SortAction } from '../redux/analytics-v2.actions';
import React from 'react';
import * as a from '../redux/analytics-v2.actions';
import { useDispatch } from 'react-redux';

export const useSortActionAnalytics = () => {
    const dispatch = useDispatch();

  const dispatchSortActionEvent = React.useCallback((sort: SortAction) => {
    dispatch(a.setSortActionEvent(sort));
  }, []);
    return dispatchSortActionEvent;
};
