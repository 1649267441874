import * as v from 'app/forms/control/form-validators';

import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { FormGroupStructure, groupStructure } from 'app/forms/structure/form-group.structure';
import { ConversionMultipliers } from 'shared/components/formatters/unit-conversion.formatter';
import { ChangeEvent } from 'react';

export interface FindLoadsFiltersFormValues {
  distance: {
    min: number | string;
    max: number | string;
  };
  weight: {
    min: number | string;
    max: number | string;
  };
  equipment: {
    length: number | string;
  };
  teamLoadsOnly: boolean;
  carrierTierAvailableOnly: boolean;
  hideStfLoads: boolean;
  webExclusiveOnly: boolean;
}

export const createFormStructure = (): FormGroupStructure => groupStructure(
  {
    distance: createMinMaxStructure(),
    weight: createMinMaxStructure(),
    equipment: groupStructure({
      length: fieldStructure(null),
    }),
    teamLoadsOnly: fieldStructure(false),
    carrierTierAvailableOnly: fieldStructure(false),
    hideStfLoads: fieldStructure(false),
    webExclusiveOnly: fieldStructure(false)
  },
  []
);
const createMinMaxStructure = (): FormGroupStructure => groupStructure({
  min: fieldStructure(null, {validators: [v.isNumeric]}),
  max: fieldStructure(null, {validators: [v.isNumeric]})
});

export class FindLoadsFiltersForm extends NavCarrierFormGroup<FindLoadsFiltersFormValues> {
  constructor() {
    super(createFormStructure());
  }

  parseNumber(value: string | number, isMetric?: boolean, conversionMultiplier?: number) {
    if (value == null || value === '') {
      return null;
    }

    return (isMetric && conversionMultiplier) ? Number(value)/conversionMultiplier : Number(value);
  }

  toSearchCriteria(isMetric: boolean): AvailableLoadSearchCriteriaJSON {
    return {
      weightMin: this.parseNumber(this.value.weight.min, isMetric, ConversionMultipliers.Weight),
      weightMax: this.parseNumber(this.value.weight.max, isMetric, ConversionMultipliers.Weight),
      milesMin: this.parseNumber(this.value.distance.min, isMetric, ConversionMultipliers.Distance),
      milesMax: this.parseNumber(this.value.distance.max, isMetric, ConversionMultipliers.Distance),
      equipmentLengthMax: this.parseNumber(this.value.equipment.length),
      teamLoad: this.value.teamLoadsOnly || null,
      carrierTierAvailable: this.value.carrierTierAvailableOnly || null,
      stfLoad: this.value.hideStfLoads ? false : null,
      webExclusive: this.value.webExclusiveOnly || null
    };
  }

  setNumericField(fieldName: string, e: ChangeEvent<HTMLInputElement>) {
    const strippedInput = e.target.value.replace(/[^\d]/g, '');
    const numericValue = strippedInput !== ''
      ? Number(strippedInput)
      : strippedInput;

    this.get(fieldName).setValue(numericValue);
  }

  setBooleanField(fieldName: string, value: boolean) {
    this.get(fieldName).setValue(value);
  }
}

export const FIND_LOADS_FILTERS_FORM = 'FIND_LOADS_FILTERS_FORM';
