import { SearchArea } from 'pages/find-loads-ver2/search/search-area.component';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { pushAvailableLoadsSearchLoadNumberURL, pushAvailableLoadsSearchURL } from 'store/epics/find-loads/find-loads-base.actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'app/hooks/store/use-selector.hook';

interface Props {
  onReset: () => any;
}

export const FindLoadsSearch = ({ onReset }: Props) => {

  const pushSearch = useDispatcher(pushAvailableLoadsSearchURL);
  const pushLoadNumberSearch = useDispatcher(pushAvailableLoadsSearchLoadNumberURL);
  const { useTurnstileCaptcha, findLoadsSendAnalyticsWeb } = useFlags();
  const captchaStatus = useSelector(state => state.captcha.findLoads.status);

  return (
    <div className="search-area" data-testid="find-loads-search-area">
      <SearchArea
        onSearch={pushSearch}
        onLoadNumberSearch={pushLoadNumberSearch}
        onReset={onReset}
        abTesting={false}
        useCaptcha={useTurnstileCaptcha}
        captchaStatus={captchaStatus}
        sendAnalytics={findLoadsSendAnalyticsWeb}
      />
    </div>
  );
};
