import 'react-dates/initialize';
import { useState, useRef, useCallback, Fragment } from 'react';
import moment from 'moment';
import { Moment } from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { DateRangePickerPhrases } from 'react-dates/lib/defaultPhrases';
import { Label } from '../../elements/label.component';
import { Translation } from '../../translation/translation.component';
import { useResources } from 'app/hooks/store/use-resources.hook';

import './date-range-picker-v2.component.scss';
import { useOnOutsideClick } from 'app/hooks/use-on-outside-click.hook';

export interface DateRangePickerV2Value {startDate: Moment | null; endDate: Moment | null } 

interface DateRangePickerV2ComponentProps {
    label: 'PICKUP_DATE_OR_DATES';
    value: DateRangePickerV2Value;
    onChange: (value: DateRangePickerV2Value) => void;
}

export const DateRangePickerV2Component = (props: DateRangePickerV2ComponentProps) => {
    const { value, onChange } = props;

    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
    const resources = useResources();

    // closing calendar when tabbing outside of component - known issue with airbnb datepickers
    const dateRangeComponentRef = useRef<HTMLDivElement>();
    useOnOutsideClick(dateRangeComponentRef, () => setFocusedInput(null));

    const validateDateRange = useCallback(() => {
        const { startDate, endDate } = value;

        let revisedStartDate = null;
        let revisedEndDate = null;

        // User only enters one of the two dates
        if (startDate && !endDate ) {
          // if user only enters startDate, set the endDate to match the startDate
          revisedEndDate = startDate.clone();
        } else if (endDate && !startDate ) {
          // if user only enters endDate that is before today, set startDate to endDate
          // if user only enters endDate that is future/today, set startDate to today
          const today = moment().startOf('day');
          const endDateHasPassed = endDate < today;
          revisedStartDate = endDateHasPassed ? endDate.clone() : today;
        }

        if (revisedStartDate || revisedEndDate){
          // We should only call onChange once when we are forcing the date to be something else during validation
          onChange({
            startDate: revisedStartDate ?? (startDate ? startDate.clone() : startDate),
            endDate: revisedEndDate ?? (endDate ? endDate.clone() : endDate)}
          );
        }
    }, [value.startDate, value.endDate]);

    const handleOnChange = (value: DateRangePickerV2Value) => {
      // Clone the input dates to prevent those using the onChange event from directly manipulating these object references.
      onChange({
        startDate: value.startDate ? value.startDate?.clone() : value.startDate,
        endDate: value.endDate ? value.endDate?.clone() : value.endDate,
      });
    }

    const handleCalendarClosure = () => validateDateRange();

    const showClearIcon = () => !!(value.startDate || value.endDate);

    const getCalendarNavButton = (position: string) => <span className={`glyphicon glyphicon-menu-${position}`}/>;

    const calendarSvg = <span className="date-picker-calendar-icon" />;
    const datePickerLabel = props.label;

    const customPhrases = {
        ...DateRangePickerPhrases,
        // replaces 'Interact with the calendar and add the check-in date for your trip.'
        // not translating since the other phrases in library are not.
        focusStartDate: 'Interact with the calendar and select the first date in your date range.'
    };

    return (
        <div id="v2-date-range-picker" ref={dateRangeComponentRef}>
            <Label htmlFor={`${datePickerLabel}`}>
                <Fragment>
                    <Translation resource={datePickerLabel} />
                </Fragment>
            </Label>
            <DateRangePicker
                required={false}
                keepOpenOnDateSelect={true}
                startDateId="start_date"
                endDateId="end_date"
                startDatePlaceholderText={resources.ANY_DATE}
                endDatePlaceholderText={resources.ANY_DATE}
                customArrowIcon={<span className="date-separator">-</span>}
                customInputIcon={calendarSvg}
                startDate={value.startDate}
                endDate={value.endDate}
                focusedInput={focusedInput}
                navNext={getCalendarNavButton('right')}
                navPrev={getCalendarNavButton('left')}
                onDatesChange={handleOnChange}
                onFocusChange={setFocusedInput}
                showClearDates={showClearIcon()}
                isOutsideRange={() => false}
                minimumNights={0}
                hideKeyboardShortcutsPanel={false}
                onClose={handleCalendarClosure}
                numberOfMonths={1}
                readOnly={true}
                phrases={customPhrases}
            />
        </div>
    );
};

export default DateRangePickerV2Component;
