import { useCallback } from 'react';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { useAnalyticsTrack } from '@chr/react-analytics';
import { useAnalyticsEventMetaData } from '@features/analytics/hooks/use-analytics-event-metadata.hook';
import { useSearchEvents, useSortActionEvents } from '@features/analytics/redux/analytics-v2.selectors';
import { mapToAdobeSearchCriteria, mapToAdobeLoadDetail } from '@features/analytics/utils/adobe-search-events-data-mapper';
import { generateEventSequence } from '@features/analytics/utils/event-sequence-generator';
import { EVENT_TYPES } from '@features/analytics/constants';

export const useLoadDetailAnalytics = () => {
  const track = useAnalyticsTrack();
  const eventMetaData = useAnalyticsEventMetaData();
  const { sortBy, sortOrder } = useSortActionEvents();
  const { searchCriteria, searchCorrelationId } = useSearchEvents();

  const trackLoadDetailEvent = useCallback((load: AvailableLoadSummary, position?: number) => {
    const { timeGMT, sequenceNumber } = generateEventSequence();
    const search = mapToAdobeSearchCriteria(searchCriteria);

    const data: KeyValuePair = {
      ...eventMetaData,
      load: mapToAdobeLoadDetail(position, load),
      searchCorrelationId,
      sortBy,
      sortType: sortOrder,
      search: search,
      sequence: {
        isInfinityWorkflow: true,
        timeGMT,
        sessionEventSequenceNumber: sequenceNumber,
      }
    };

    track({ event: EVENT_TYPES.LOAD_DETAIL, ...data });

  }, [track, eventMetaData, sortBy, sortOrder, searchCriteria, searchCorrelationId]);

  return trackLoadDetailEvent;
}
