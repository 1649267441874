import React from 'react';
import { useContext } from 'react';

import { Translation } from 'shared/components/translation/translation.component';
import { UnitLabel } from 'shared/components/formatters/unit-label.formatter';
import { MeasurementType } from 'shared/enums/measurement-type.enum';
import { LocationSelectContext } from 'shared/components/location-select/location-select-context.component';
import { Label } from 'shared/components/elements/label.component';

interface OwnProps {
  required?: boolean;
  isMetric: boolean;
  closeSubMenu: () => any;
}

export const RadiusControlsComponent: React.FC<OwnProps> = ({required, isMetric, closeSubMenu}) => {
  const {label, id, onRadiusChange, location} = useContext(LocationSelectContext);
  const {radius} = location;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // enter key - prevents form submission / close submenu
    if (event.keyCode === 13) {
      event.preventDefault();
      closeSubMenu();
    }
  };

  return (
    <React.Fragment>
      <div className="radius-label">
        <Label required={required} htmlFor={`${label}-radius-slider`}>
          {label === 'ORIGIN' ? <Translation resource="ORIGIN_RADIUS"/> : <Translation resource="DESTINATION_RADIUS"/>}
          {' '}(<UnitLabel type={MeasurementType.Distance}/>)
        </Label>
      </div>
      <div className="radius-controls">
        <input
          type="range" min="1" max={isMetric ? 800 : 500}
          id={`${id || label}-radius-slider`}
          name={`${id || label}-radius-slider`}
          className="form-control-range radius-slider"
          onChange={onRadiusChange}
          value={radius}
          onKeyDown={handleKeyDown}
        />
        <input
          type="number" min="1" max={isMetric ? 800 : 500}
          id={`${id || label}-radius-input`}
          className="form-control radius-input-number"
          onChange={onRadiusChange}
          value={radius}
          onKeyDown={handleKeyDown}
        />
      </div>
    </React.Fragment>
  );
};
