import React from 'react';
import classNames from 'classnames';
import { FunctionComponent, useCallback, useContext, useRef, useState } from 'react';

import { useOnOutsideClick } from 'app/hooks/use-on-outside-click.hook';
import { useCloseMenuOnBlur } from 'containers/layout/header/sub-menu/hooks/use-close-menu-on-blur.hook';
import { RadiusControlsComponent } from 'shared/find-loads/components/radius-controls.component';
import { UnitLabel } from 'shared/components/formatters/unit-label.formatter';
import { MeasurementType } from 'shared/enums/measurement-type.enum';
import { LocationSelectContext } from 'shared/components/location-select/location-select-context.component';
import { useResources } from 'app/hooks/store/use-resources.hook';

import './radius-selection.component.scss';

export interface Props {
  className?: string;
  ariaControls: string;
  isMetric: boolean;
}

export const RadiusSelection: FunctionComponent<Props> = ({ ariaControls, isMetric, ...props}) => {
  const {location, label} = useContext(LocationSelectContext);
  const {radius} = location;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleSubMenu = useCallback((event) => {
    event.preventDefault();
    setIsOpen(state => !state);
  }, [setIsOpen]);

  const resources = useResources();
  const ariaLabel = label === 'ORIGIN' ? resources.ORIGINRADIUS_SEARCH : resources.DESTINATION_RADIUS_SEARCH;
  const ariaDesc = isMetric ? resources.RADIUS_CANNOT_EXCEED_800_KILOMETERS : resources.RADIUS_CANNOT_EXCEED_500_MILES;
  const myRef = useRef(null);
  const radiusRef = useRef(100);
  const closeSubMenu = useCallback(() => setIsOpen(false), [setIsOpen]);

  useOnOutsideClick(myRef, closeSubMenu);
  const onBlur = useCloseMenuOnBlur(myRef, closeSubMenu);
  if (!isOpen) {
    radiusRef.current = radius;
  }

  return (
    <div className={props.className} ref={myRef} onBlur={onBlur}>
      <span className="sr-only" id={`ariaDesc-${label}`}>
        <span>{ariaLabel}...</span>
        <span>{ariaDesc}</span>
      </span>
      <a
        href="#"
        className={classNames('selection-menu-trigger')}
        aria-haspopup="true"
        aria-controls={ariaControls}
        aria-expanded={isOpen}
        onClick={toggleSubMenu}
        aria-describedby={`ariaDesc-${label}`}
      >
        <span aria-hidden="true" className="radius-input-icon"/>
        {isOpen ? radiusRef.current : radius}
        &nbsp;
        <UnitLabel type={MeasurementType.Distance}/>
        <span className={classNames('glyphicon', isOpen ? 'glyphicon-menu-up' : 'glyphicon-menu-down')}/>
      </a>
      <div id={ariaControls} className={classNames('radius-selection', {'open': isOpen})}>
        <div className="form-group js-location-select-radius">
          <RadiusControlsComponent isMetric={isMetric} closeSubMenu={closeSubMenu}/>
        </div>
      </div>
    </div>
  );
};
