import { ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormError } from 'shared/components/formatters/form-error.formatter';
import { titleCase } from 'shared/components/formatters/title-case.formatter';
import { Button } from 'shared/components/elements/elements.components';
import { FindLoadsAreaSelect } from 'pages/find-loads-ver2/search/find-loads-area-select.component';
import { FindLoadsSearchForm } from 'pages/find-loads-ver2/search/find-loads-search.form';
import { SearchError } from '@shared/find-loads/components/search-error.component';
import { DateRangePickerV2Value, DateRangePickerV2Component } from 'shared/components/date-picker/date-range-picker/date-range-picker-v2.component';
import { EquipmentTypeSelect, EquipmentTypeValues } from 'shared/find-loads/components/equipment-select.component';
import { TurnstileCaptcha } from 'shared/components/turnstile-captcha/turnstile-captcha.component';
import { CaptchaPages } from 'shared/components/turnstile-captcha/types';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
    form: FindLoadsSearchForm;
    isLoadNumSearch: boolean;
    submitPerformed: boolean;
    disableSubmission: boolean;
    handleSelection: (isLoadNumSearch: boolean) => any;
    setOriginLocation: (location: LocationSelectGrouping) => any;
    setDestinationLocation: (location: LocationSelectGrouping) => any;
    setDateRange: (value: DateRangePickerV2Value) => any;
    setEquipmentTypes: (values: EquipmentTypeValues) => any;
    setLoadNumber: (e: ChangeEvent<HTMLInputElement>) => any;
    submitForm: (e: FormEvent<HTMLFormElement>) => any;
    resetForm: (e: FormEvent<HTMLFormElement>) => any;
}

export const FindLoadsSearchFormComponent = (props: Props) => {  
    const { 
        form, 
        isLoadNumSearch, 
        submitPerformed, 
        disableSubmission, 
        handleSelection, 
        setOriginLocation, 
        setDestinationLocation, 
        setDateRange, 
        setEquipmentTypes, 
        setLoadNumber,
        submitForm,
        resetForm
    } = props;
    const { t } = useTranslation();
    const values = form.value;

    const originForm = form.get('origin') as NavCarrierFormGroup;
    const destinationForm = form.get('destination') as NavCarrierFormGroup;
    const loadNumber = form.get('loadNumber').value;
    const originDestinationHasErrors = !isLoadNumSearch && form.hasError('conditionalRequiredFields') ||
        (submitPerformed && (originForm.hasErrors() || destinationForm.hasErrors()));
    const { useTurnstileCaptcha } = useFlags();

    return <form id="find-loads-search-form" onSubmit={submitForm} onReset={resetForm} autoComplete="off">
      <div className="selection-header">
        <div className="search-view-select-container">
          <h1 className="main-page-header">{t('FIND_LOADS')}</h1>
          <div className="search-selection-menu">
            <label className="radio-inline no-wrap" htmlFor="rb-origin-destination">
              <input
                type="radio"
                name="searchType"
                id="rb-origin-destination"
                checked={!isLoadNumSearch}
                onChange={() => handleSelection(false)}
                value="false"
                data-testid="rb-origin-destination"
              />
              {t('ORIGIN_DASH_DESTINATION')}
            </label>
            <label className="radio-inline no-wrap" htmlFor="rb-load-number">
              <input
                type="radio"
                name="searchType"
                id="rb-load-number"
                checked={isLoadNumSearch}
                onChange={() => handleSelection(true)}
                value="true"
                data-testid="rb-load-number"
              />
              {t('LOAD_NUMBER')}
            </label>
          </div>
        </div>
        {isLoadNumSearch
          ? <div className="search-criteria-input" data-testid="load-number-input">
                <div className="js-field-load-number">
                    <div className="form-group">
                        <label htmlFor="load-number-input">{t('LOAD_NUMBER')}</label>
                        <input
                        id="load-number-input"
                        aria-label={t('ENTER_A_LOAD_NUMBER')}
                        name="load-number-input"
                        className="form-control"
                        value={values.loadNumber ?? ''}
                        onChange={setLoadNumber}
                        placeholder={t('LOAD_NUMBER_PLACEHOLDER')}
                        />
                    </div>
                </div>
                {form.hasError('conditionalRequiredFields') &&
                    <FormError resource={loadNumber ? "INVALID_SEARCH_LOAD_NUMBER" : "ENTER_A_LOAD_NUMBER"} />
                }
            </div>
          : <>
          <div className="search-criteria-input" data-testid="origin-criteria-input">
            <FindLoadsAreaSelect
              labelKey="ORIGIN"
              formGroup={originForm}
              onLocationChange={setOriginLocation}
            />
          </div>
          <div className="arrow">
            <img src="/assets/images/Arrow_24dp.svg" aria-hidden="true"/>
          </div>
          <div className="search-criteria-input" data-testid="destination-criteria-input">
            <FindLoadsAreaSelect
              labelKey="DESTINATION"
              formGroup={destinationForm}
              onLocationChange={setDestinationLocation}
            />
          </div>
          <div className="date-picker-and-equipment-selection" data-testid="date-equipment-input">
            <DateRangePickerV2Component
              label="PICKUP_DATE_OR_DATES"
              value={{
                startDate: values.dateRange.start, 
                endDate: values.dateRange.end}}
              onChange={setDateRange}
            />
            <EquipmentTypeSelect values={values.equipment} onChange={setEquipmentTypes}/>
          </div>
        </>
        }
        <div className="action-buttons">
          <Button type="submit" id="btn-find-loads-search-submit" data-testid="btn-find-loads-search-submit" btnPrimary disabled={disableSubmission}>
            {titleCase(t('SEARCH'))}
          </Button>
          <Button type="reset" id="btn-find-loads-search-reset" data-testid="btn-find-loads-search-reset" btnDefault resource="CLEAR" />
        </div>
        <TurnstileCaptcha id={FindLoadsSearchFormComponent.name} page={CaptchaPages.FIND_LOADS} isEnabledForPage={useTurnstileCaptcha}/>
      </div>
      <div className="search-error">
        {originDestinationHasErrors &&
          <FormError resource="ENTER_VALID_ORIGIN_OR_DESTINATION"/>
        }
        <SearchError hideNotFoundError={true}/>
      </div>
    </form>;
}