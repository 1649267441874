import { useTranslation } from 'react-i18next';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';

export interface OptionalTitleProps {
  suggestedLoadsRetrieved: boolean,
  searchType?: AvailableLoadSearchType,
  searchCriteria?: AvailableLoadSearchCriteriaJSON
}

/**
 * Renders optional title for find loads page if necessary.
 * @param suggestedLoadsRetrieved
 * @param searchType
 * @param searchCriteria
 */
export const OptionalTitle = ({ suggestedLoadsRetrieved, searchType, searchCriteria }: OptionalTitleProps) => {
  const { t } = useTranslation();

  if (searchType == AvailableLoadSearchType.RELOADS) {
    const city = [searchCriteria?.destinationCity || searchCriteria?.reloadsCriteria?.primalLoadDetails?.destination?.city];

    return (
      <h2 className="reloads-title" data-testid="reloads-title">
        {t('VIEW_ALL_RELOADS_TITLE', {0: city})}
      </h2>
    );
  }

  return (<>
    {suggestedLoadsRetrieved &&
      <h2 className="suggested-loads-title" data-testid="suggested-loads-title">
        {t('SUGGESTED_LOADS_TITLE')}
      </h2>
    }
  </>);
};
