import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import './find-loads-results-empty-splash.component.scss';

export const EmptySplashResults = forwardRef((props, ref: React.MutableRefObject<HTMLDivElement>) => {
  const { t } = useTranslation();
  return (
    <div className="find-loads-splash-empty-results" ref={ref}>
      <img src="/assets/images/empty-state.svg" role="none" data-testid="empty-state-image"/>
      <h2>{t('SEARCH_FOR_LOADS')}</h2>
    </div>
  );
});
