import { FindLoadsFiltersForm } from 'pages/find-loads-ver2/filters/find-loads-filters.form';
import { MinMaxFormGroup } from 'shared/find-loads/components/min-max-form-group.component';
import { Switch } from '@chr/eds-react';
import { useResources } from 'app/hooks/store/use-resources.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  form: FindLoadsFiltersForm;
  isMetric: boolean;
}

export const FindLoadsFiltersFormComponent = ({ form, isMetric }: Props) => {
  const resources = useResources();
  const { findLoadsStfWeb } = useFlags();

  const distanceUnits = isMetric ? 'km' : 'mi';
  const weightUnits = isMetric ? resources.KG : resources.LBS;
  const values = form.value;

  return (
    <div className='filters-form-content' data-testid='filters-form-content' >
      <div className="filters-form-section">
        <div>
          <label>
            <div className="h3">
              {resources.EQUIPMENT_LENGTH}
            </div>
            <input
              type="text"
              id="equipment-length"
              name="equipment-length"
              className="form-control"
              aria-labelledby="equipment-length-label"
              value={values?.equipment?.length || ''}
              onChange={(e) => form.setNumericField('equipment.length', e)}
            />
          </label>
        </div>
      </div>
      <div className="filters-form-section">
        <MinMaxFormGroup
          fieldLabel={`(${distanceUnits})`}
          groupLabelKey="LOAD_DISTANCE"
          values={values?.distance || { min: '', max: '' }}
          onMinChange={(e) => form.setNumericField('distance.min', e)}
          onMaxChange={(e) => form.setNumericField('distance.max', e)}
        />
      </div>
      <div className="filters-form-section">
        <MinMaxFormGroup
          fieldLabel={weightUnits}
          groupLabelKey="LOAD_WEIGHT"
          values={values?.weight || { min: '', max: '' }}
          onMinChange={(e) => form.setNumericField('weight.min', e)}
          onMaxChange={(e) => form.setNumericField('weight.max', e)}
        />
      </div>
      <div className="filters-form-section switch-label">
        <span className="js-field-team-loads-only switch-inline checkbox-inline">
          <Switch
            id="find-loads-filter-team-loads"
            label={resources.TEAM_LOADS_ONLY}
            ariaLabel={resources.TEAM_LOADS_ONLY}
            checked={values?.teamLoadsOnly}
            onChange={(e) => form.setBooleanField('teamLoadsOnly', e.target.checked)}
          />
        </span>
      </div>
      <div className="filters-form-section" data-testid='carrier-tier-available-only-selection'>
        <span className="js-field-carrier-tier-available-only switch-inline checkbox-inline">
          <Switch
            id="find-loads-filter-carrier-tier-available"
            label={resources.CARRIER_TIER_AVAILABLE_ONLY}
            ariaLabel={resources.CARRIER_TIER_AVAILABLE_ONLY}
            checked={values?.carrierTierAvailableOnly}
            onChange={(e) => form.setBooleanField('carrierTierAvailableOnly', e.target.checked)}
          />
        </span>
      </div>
      {findLoadsStfWeb &&
        <div className="filters-form-section switch-label">
          <span className="js-field-hide-stf-loads switch-inline checkbox-inline" data-testid='find-loads-filter-stf-loads'>
            <Switch
              id="find-loads-filter-stf-loads"
              label={resources.STF_FILTER}
              ariaLabel={resources.STF_FILTER}
              checked={values?.hideStfLoads}
              onChange={(e) => form.setBooleanField('hideStfLoads', e.target.checked)}
            />
          </span>
        </div>
      }
      <div className="filters-form-section switch-label">
        <span className="js-field-hide-web-exclusive switch-inline checkbox-inline" data-testid='find-loads-filter-web-exclusive'>
          <Switch
            id="find-loads-filter-web-exclusive"
            label={resources.CARRIER_EXCLUSIVE_ONLY}
            ariaLabel={resources.CARRIER_EXCLUSIVE_ONLY}
            checked={values?.webExclusiveOnly}
            onChange={(e) => form.setBooleanField('webExclusiveOnly', e.target.checked)}
          />
        </span>
      </div>
    </div>
  );
};