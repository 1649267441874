import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { AnchorButton } from 'shared/components/elements/button.component';
import { Translation } from 'shared/components/translation/translation.component';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import { push } from 'connected-react-router';
import { useCallback } from 'react';

export const BackToFindLoadsButton = () => {
  const pushRoute = useDispatcher(push);
  const onClick = useCallback(() => {pushRoute(`/find-loads/${AvailableLoadSearchType.SINGLE}`);}, [pushRoute]);
  return (<div>
    <AnchorButton onClick={onClick}>
      <span className="back-to-find-loads-btn-icon"/>
      <Translation resource="BACK_TO_FIND_LOADS"/>
    </AnchorButton>
  </div>);
};
