import { useTranslation } from 'react-i18next';
import './find-loads-results-not-found.component.scss';

export const LoadsNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="find-loads-not-found">
      <img src="/assets/images/blank-state.svg" aria-hidden={true} data-testid="blank-state-image"/>
      <h2>{t('THERE_ARE_NO_LOADS_MATCHING_CRITERIA_SIMPLE')}</h2>
      <p>{t('TRY_SEARCHING_BY_CITY_OR_POSTAL_CODE')}</p>
    </div>
  )
};
