import { useEffect } from 'react';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useCarrierOffer from 'pages/find-loads-ver2/hooks/use-carrier-offer.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useDispatch } from 'react-redux';
import { callCarrierValidation } from 'shared/find-loads/redux/find-loads.actions';

export const useCarrierValidation = (load: AvailableLoadSummary) => {
  const { findLoadsCarrierValidation } = useFlags();
  const dispatch = useDispatch();
  const carrierValidationResult = useSelector(s => s.findLoads?.results?.carrierValidationResults ? s.findLoads.results.carrierValidationResults[load?.number] : null);
  const offerState = useCarrierOffer(load);
  const { canBook, canOffer } = offerState?.availableLoadOfferAction ?? {};

  useEffect(() => {
    const shouldCallCarrierValidation = findLoadsCarrierValidation //flag is on
      && carrierValidationResult == null //hasn't been called yet
      && offerState?.offerStatus == null //has no offers
      && (canBook || canOffer) //can book or offer
      && load; //has a load

    if (shouldCallCarrierValidation) {
      dispatch(callCarrierValidation(load.number));
    }
  }, [findLoadsCarrierValidation, carrierValidationResult, offerState, load]);

};