import moment from 'moment';
import { Moment } from 'moment';

import * as v from 'app/forms/control/form-validators';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { FormGroupStructure, groupStructure } from 'app/forms/structure/form-group.structure';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { conditionalRequiredFields } from 'app/forms/control/form-validators';

const searchRequiredFields = conditionalRequiredFields([v.originOrDestinationLocationRequiredV2, v.loadNumberRequiredV2]);

export const defaultDateRange = {
  start: moment().local().startOf('day'),
  end: moment().add(1, 'd').local().endOf('day')
};

export const createPickupDateStructure = (): FormGroupStructure => groupStructure({
  start: fieldStructure(defaultDateRange.start),
  end: fieldStructure(defaultDateRange.end)
}, [v.validateDateRangeOrder, v.validateDateRangeComplete]);

export const createLocationStructure = (isMetric: boolean): FormGroupStructure => groupStructure(
  {
    country: fieldStructure(),
    stateProv: fieldStructure(),
    city: fieldStructure(),
    place: fieldStructure(),
    radius: fieldStructure(100, {validators: [v.max(isMetric ? 800 : 500)]})
  }
);

export interface FindLoadsSearchFormValues {
  dateRange: {
    start: Moment;
    end: Moment;
  };
  origin: {
    country: CountryResponse;
    stateProv: StateResponse;
    city: CityResponse;
    place: any;
    radius: number;
  };
  destination: {
    country: CountryResponse;
    stateProv: StateResponse;
    city: CityResponse;
    place: any;
    radius: number;
  };
  loadNumber: number;
  equipment: {
    type: EquipmentType;
    extendedType: string;
  };
}

export const createSingleSearchStructure = (isMetric: boolean): FormGroupStructure => groupStructure(
  {
    dateRange: createPickupDateStructure(),
    origin: createLocationStructure(isMetric),
    destination: createLocationStructure(isMetric),
    loadNumber: fieldStructure(null),
    equipment: groupStructure({
      type: fieldStructure(EquipmentType.All),
      extendedType: fieldStructure(EquipmentType.All),
    })
  },
  [searchRequiredFields]
);

const radiusRequired = (placeField: NavCarrierFormControl) => v.createValidator('radiusRequired', (radius) => {
  // radius is only required when there is a valid place/location
  if (!placeField.value || placeField.value.cityName === '') {
    return true;
  }

  return radius != null;
});

export class FindLoadsSearchForm extends NavCarrierFormGroup<FindLoadsSearchFormValues> {

  constructor(isMetric: boolean) {
    super(createSingleSearchStructure(isMetric));
    (this.get('origin.radius') as NavCarrierFormControl).addValidator(radiusRequired(this.get('origin.place')));
    (this.get('destination.radius') as NavCarrierFormControl).addValidator(radiusRequired(this.get('destination.place')));
    (this.get('origin') as NavCarrierFormGroup).addValidator(v.originOrDestinationValid);
    (this.get('destination') as NavCarrierFormGroup).addValidator(v.originOrDestinationValid);

    this.validate();
  }
}

export const FIND_LOADS_SEARCH_FORM = 'FIND_LOADS_SEARCH_FORM';
